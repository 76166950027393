<template>
  <modal
    :open="open"
    :size="unpersonalisedSampleEnabled ? 'large' : 'small'"
    @closed="$emit('closed')">
    <template #header>
      <h3 class="typeset-2">
        {{ t("quick-sample-modal.title") }}
      </h3>
    </template>
    <template #body>
      <div class="flex flex-col md:flex-row gap-4 items-stretch mb-5">
        <div
          v-if="!showsSamplePack"
          class="flex-1 p-5 bg-paper">
          <h5 class="typeset-4 text-ink-4 mb-2">
            {{ t("quick-sample-modal.personalised.price") }}
          </h5>
          <h4 class="typeset-6 mb-3">
            {{ t("quick-sample-modal.personalised.title") }}
          </h4>
          <p
            class="mb-5"
            v-html="t('quick-sample-modal.personalised.description-html')"></p>
          <template v-if="editor">
            <a
              :href="customiseSamplePath"
              class="btn btn-large btn-secondary block w-full mb-3"
              data-cy="customise-sample"
              @click="trackCustomiseClick">
              {{ t("quick-sample-modal.personalised.customise") }}
            </a>
            <btn
              class="btn-large block"
              data-cy="add-customised-sample-to-basket"
              :promise="addPersonalisedSampleToCart()">
              {{ t("quick-sample-modal.personalised.add-to-cart") }}
            </btn>
          </template>
          <a
            v-else
            :href="customiseSamplePath"
            class="btn btn-large btn-primary block"
            data-cy="customise-sample"
            @click="trackCustomiseClick">
            {{ t("quick-sample-modal.personalised.customise") }}
          </a>
        </div>
        <div
          v-if="unpersonalisedSampleEnabled"
          class="flex-1 p-5 bg-paper flex flex-col">
          <h5 class="typeset-4 text-ink-4 mb-2">
            {{ t("quick-sample-modal.unpersonalised.price") }}
          </h5>
          <h4 class="typeset-6 mb-3">
            {{ t("quick-sample-modal.unpersonalised.title") }}
          </h4>
          <p
            class="mb-5"
            v-html="
              t('quick-sample-modal.unpersonalised.description-html')
            "></p>
          <btn
            class="btn-large block mt-auto"
            data-cy="add-uncustomised-sample-to-basket"
            :promise="addUnpersonalisedSampleToCart()">
            {{ t("quick-sample-modal.unpersonalised.add-to-cart") }}
          </btn>
        </div>
        <div
          v-if="showsSamplePack"
          class="flex-1 p-5 bg-paper flex flex-col">
          <h5 class="typeset-4 text-ink-4 mb-2">
            {{ t("quick-sample-modal.sample-pack.price") }}
          </h5>
          <h4 class="typeset-6 mb-3">
            {{ t("quick-sample-modal.sample-pack.title") }}
          </h4>
          <p
            class="mb-5"
            v-html="t('quick-sample-modal.sample-pack.description-html')"></p>
          <a
            :href="samplePackUrl"
            class="btn btn-primary btn-large block mt-auto">
            {{ t("quick-sample-modal.sample-pack.find-out-more") }}
          </a>
        </div>
      </div>
      <p class="typeset-6 mb-3">
        {{ t("quick-sample-modal.free-sample-title") }}
      </p>
      <p
        class="mb-4"
        v-html="t('quick-sample-modal.free-sample-html')"></p>
      <div class="flex items-center gap-2">
        <div
          class="h-5 w-5 relative cursor-pointer"
          data-cy="copy-discount-code">
          <fade-transition>
            <div
              v-if="copied"
              class="h-5 w-5 absolute top-0 left-0 flex">
              <svg-icon
                class="m-auto"
                name="tick" />
            </div>
            <div
              v-else
              class="h-5 w-5 absolute top-0 left-0 flex"
              @click="copyCode">
              <svg-icon
                name="copy"
                class="m-auto" />
            </div>
          </fade-transition>
        </div>
        <a
          href="#"
          class="underline"
          @click.prevent="copyCode">
          {{ t("quick-sample-modal.free-sample-copy") }}
        </a>
      </div>
      <p
        v-if="showThicknessMessage"
        class="mt-4"
        v-html="
          t('quick-sample-modal.thickness-message-html', {
            link: samplePackLink
          })
        "></p>
    </template>
  </modal>
</template>

<script>
import Btn from "@/app/javascript/components/shared/Btn.vue";
import Modal from "@/app/javascript/components/shared/Modal.vue";
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";
import api from "@/app/javascript/api";
import {
  cartPath,
  quickAddSampleSavedDesignsPath
} from "@/app/javascript/routes";
import FadeTransition from "../transitions/FadeTransition.vue";

export default {
  name: "QuickSampleModal",
  components: {
    Btn,
    FadeTransition,
    Modal,
    SvgIcon
  },
  inject: {
    unpersonalisedSampleEnabled: {
      default: true
    },
    unpersonalisedLink: {
      default: null
    },
    listingSlug: {
      default: null
    },
    customiseSamplePath: {
      default: null
    },
    showsSamplePack: {
      default: false
    },
    samplePackUrl: {
      default: null
    }
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    editor: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closed", "addToCart"],
  data() {
    return {
      copied: false,
      showThicknessMessage: false
    };
  },
  computed: {
    mainDesignable() {
      if (this.editor) {
        return this.$store.state.design.designables[0];
      }

      return null;
    },
    samplePackLink() {
      if (this.editor) {
        return this.$store.state.design.product.sample.sample_pack_link;
      }

      return false;
    },
    mainDesignableIsThick() {
      if (this.mainDesignable) {
        return this.mainDesignable.thick;
      }

      return false;
    },
    eventProps() {
      return {
        location: this.location
      };
    },
    location() {
      return this.editor ? "Editor" : "PLP";
    },
    countryCodeWithoutSlash() {
      return window.countryCode.replace("/", "");
    }
  },
  watch: {
    open(newValue) {
      if (newValue) {
        analytics.track("Quick Sample Modal Opened", this.eventProps);

        if (this.editor && this.mainDesignableIsThick) {
          this.$store.commit("design/setThicknessForDesignable", {
            designable: this.mainDesignable,
            thickness: false
          });

          this.showThicknessMessage = !!this.samplePackLink;
        }
      } else {
        this.showThicknessMessage = false;
      }
    }
  },
  mounted() {
    window.addEventListener("showLoginModal", () => this.$emit("closed"));
  },
  methods: {
    addPersonalisedSampleToCart() {
      return async () => {
        try {
          analytics.track("Add Personalised Sample To Cart", this.eventProps);
          await this.$store.dispatch("design/addSample");
        } catch (error) {
          console.log(error);
        }
      };
    },
    addUnpersonalisedSampleToCart() {
      return async () => {
        try {
          analytics.track("Add Unpersonalised Sample To Cart", this.eventProps);
          await api.post(
            quickAddSampleSavedDesignsPath(null, this.listingSlug)
          );
          window.location = cartPath(this.countryCodeWithoutSlash);
        } catch (error) {
          console.log(error);
        }
      };
    },
    copyCode() {
      navigator.clipboard.writeText(
        this.t("quick-sample-modal.free-sample-code")
      );
      analytics.track("Copied Free Sample Code", this.eventProps);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
    trackCustomiseClick() {
      analytics.track("Clicked Customise Sample", this.eventProps);
    }
  }
};
</script>
